import React from "react";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import { obfuscate } from "../util/Util";

class Tag extends React.Component {
  render() {
    return (
      <Link to={`/tag/${obfuscate(this.props.id)}`}>
        <Badge>{this.props.name}</Badge>
      </Link>
    );
  }
}

export default Tag;
