import { redirect, useNavigate } from "react-router-dom";
import Tagform from "../../components/forms/TagForm";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import TrackerAPI from "../../service/TrackerAPI";
import { deobfuscate, obfuscate } from "../../util/Util";

export async function action({ request }) {
  const formData = await request.formData();
  const attributes = Object.fromEntries(formData);
  const tag = await TrackerAPI.createTag(attributes);
  return redirect(`/tag/${obfuscate(tag.id)}`);
}

const TagCreateView = () => {
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Tags</Breadcrumb.Item>
        <Breadcrumb.Item active>Create new...</Breadcrumb.Item>
      </Breadcrumb>
      <Tagform />
      <Button variant="secondary" onClick={() => navigate(-1)}>
        Cancel
      </Button>
    </>
  );
};

export default TagCreateView;
