import Form from "react-bootstrap/Form";
import { Form as RouterForm } from "react-router-dom";
import Button from "react-bootstrap/Button";

const GroupForm = (props) => {
  return (
    <RouterForm method="post" id="group-form">
      <Form.Label>Name</Form.Label>
      <Form.Control name="name" defaultValue={props.name}></Form.Control>
      <br />
      <Form.Label>Description</Form.Label>
      <Form.Control
        name="description"
        defaultValue={props.description}
      ></Form.Control>
      <Form.Label>Notes</Form.Label>
      <Form.Control name="note" defaultValue={props.note}></Form.Control>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </RouterForm>
  );
};

export default GroupForm;
