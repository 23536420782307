import GroupForm from "../../components/forms/GroupForm";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import LinkContainer from "react-router-bootstrap/LinkContainer";
import { Link, redirect } from "react-router-dom";
import TrackerAPI from "../../service/TrackerAPI";
import { useEffect } from "react";
import { obfuscate } from "../../util/Util";

export async function action({ request }) {
  const formData = await request.formData();
  const attributes = Object.fromEntries(formData);
  const group = await TrackerAPI.createGroup(attributes);
  const groupId = obfuscate(group.id);

  return redirect(`/group/${groupId}/`);
}

const GroupCreateView = () => {
  useEffect(() => {
    document.title = `Create group...`;
  }, []);

  return (
    <div>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item href="/">Groups</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Create new group...</Breadcrumb.Item>
      </Breadcrumb>
      <GroupForm />
      <Link to={`/`}>
        <Button variant="secondary">Cancel</Button>
      </Link>
    </div>
  );
};

export default GroupCreateView;
