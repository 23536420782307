import './UxTestView.css';

export default function UxTestView() {

   return(<>
      <h1>Info - Symbols</h1>
      <ul>
         <li><b>&#183;</b> - ITEM - An individual item</li>
         <li><b>&#8743;</b> - AND - A collection where everything is required</li>
         <li><b>&#8744;</b> - OR - A collection where nothing is required but everything can be aquired</li>
         <li><b>&#8891;</b> - XOR - A collection of choices where a single item should be acquired</li>
      </ul>
      <h1>&#8743; Adventure Trip</h1>
      <div className='group-and'>
         <div className='item'>
            <div>
               <input type="checkbox"></input>
            </div>
            <div className='item-type'>
               <b>&#183;</b>
            </div>
            <div className='item-title'>
               Lava Guitar 3
            </div>
            <div className='price'>
               $40
            </div>
            <div className='item-move'>
               &#8801;
            </div>
         </div>
         <div className='item'>
            <div>
               <input type="checkbox"></input>
            </div>
            <div className='item-type'>
               <b>&#8891;</b>
            </div>
            <div className='item-title'>
               Bicycle
            </div>
            <div className='price'>
               $200-800
            </div>
            <div className='item-move'>
               &#8801;
            </div>
         </div>
      </div>
      <br/>
      <h1>&#8744; Wishlist</h1>
      <div className='group-and'>
         <div className='item'>
            <div>
               <input type="checkbox"></input>
            </div>
            <div className='item-type'>
               <b>&#183;</b>
            </div>
            <div className='item-title'>
               Lava Guitar 3
            </div>
            <div className='price'>
               $40
            </div>
            <div className='item-move'>
               &#8801;
            </div>
         </div>
         <div className='item'>
            <div>
               <input type="checkbox"></input>
            </div>
            <div className='item-type'>
               <b>&#8743;</b>
            </div>
            <div className='item-title'>
               Dog
            </div>
            <div className='price'>
               $500-2500
            </div>
            <div className='item-move'>
               &#8801;
            </div>
         </div>
      </div>
      <br />
      <h1>Rules</h1>
      <ul>
         <li><b>No cyclical relationships: </b>Groups can contain other groups, but a child can't also be the parent of a group whose it's parent</li>
      </ul>

      <h1>Ids</h1>
      <p>
         Ids will use a simple hashing algorithm to go from integer to 6 digit hex string.

         <br></br>
      </p>
   </>);
}