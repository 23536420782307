import Button from "react-bootstrap/Button";
import { useContext, useEffect } from "react";
import UserContext from "../UserContext";
import { useNavigate } from "react-router-dom";

export default function AccountView() {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.username) {
      document.title = `Account - @${user.username}`;
    } else {
      document.title = "Account";
    }
  }, [user]);

  const handleLogOutClick = async (event) => {
    event.preventDefault();

    await logout();
    navigate("/");
  };

  return (
    <>
      <h1>@{user?.username}</h1>
      <br />
      <Button onClick={handleLogOutClick}>Log Out</Button>
    </>
  );
}
