import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import UserContext from "../UserContext";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { DEFAULT_PROFILE_IMAGE_SVG_BASE64 } from "../Profile";

export default function UserNavBar() {
  return (
    <Navbar>
      <Container>
        <Link to="/#">
          <Navbar.Brand>Tracker</Navbar.Brand>
        </Link>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <UserContext.Consumer>
            {({ user }) => {
              if (user) {
                return (
                  <>
                    {/* <Navbar.Text><Link to="/test">UX Test</Link>&nbsp;&nbsp;</Navbar.Text> */}
                    <Navbar.Text>@{user.username}</Navbar.Text>
                    <Navbar.Text>
                      &nbsp;
                      <Link to="/account">
                        <Image
                          style={{ maxWidth: "2em" }}
                          roundedCircle={true}
                          src={`data:image/svg+xml;base64,${DEFAULT_PROFILE_IMAGE_SVG_BASE64}`}
                        />
                      </Link>
                      &nbsp;&nbsp;
                    </Navbar.Text>
                  </>
                );
              } else {
                return (
                  <Navbar.Text>
                    <Link to="/login">Login</Link>
                  </Navbar.Text>
                );
              }
            }}
          </UserContext.Consumer>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
