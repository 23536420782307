import React from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

import Tag from "./Tag";
import { obfuscate, replaceForUrl } from "../util/Util";

class GroupTableRow extends React.Component {
  maxPrice = () => {
    return Math.max(0, ...this.props.items.map((item) => item.price));
  };

  minPrice = () => {
    return Math.min(...this.props.items.map((item) => item.price));
  };

  getPriceString = () => {
    if (this.props.items.length === 0) return "";
    let min = this.minPrice();
    let max = this.maxPrice();
    if (min === max) return `$${min}`;
    return `$${min} to $${max}`;
  };

  render() {
    let tags = this.props.tags.map((tag) => (
      <Tag
        key={tag.id}
        id={tag.id}
        name={
          // HORRIBLE!
          this.props.tagsdata.find((t) => t.id === tag.id)?.attributes.name
        }
      />
    ));
    return (
      <tr>
        <td>
          <Form.Check type="checkbox"></Form.Check>
        </td>
        <td>
          <Link to={`/group/${obfuscate(this.props.groupId)}/${replaceForUrl(this.props.name)}`}>{this.props.name}</Link>
        </td>
        <td>
          <div
            style={{
              overflow: "auto",
              display: "flex",
              flexDirection: "row",
              gap: "0.1em",
            }}
          >
            {tags}
          </div>
        </td>
        <td>{this.props.items.length}</td>
        <td>{this.getPriceString()}</td>
      </tr>
    );
  }
}

export default GroupTableRow;
