import React from "react";
import Table from "react-bootstrap/Table";

import GroupTableRow from "./GroupTableRow";

class GroupTable extends React.Component {
  render() {
    let groups = this.props.groups.map((group) => (
      <GroupTableRow
        key={group.id}
        groupId={group.id}
        name={group.attributes.name}
        tags={group.relationships.tags.data}
        tagsdata={this.props.tags}
        items={group.attributes.items}
      />
    ));
    return (
      <Table hover size="sm">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Tags</th>
            <th>#</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>{groups}</tbody>
      </Table>
    );
  }
}

export default GroupTable;
