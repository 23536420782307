import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

import GroupListView, {
  loader as groupListLoader,
} from "./routes/GroupListView";
import GroupEditView, {
  loader as groupLoader,
  action as groupAction,
} from "./routes/group/GroupEditView";
import GroupView, { loader as groupViewLoader } from "./routes/group/GroupView";
import GroupCreateView, {
  action as groupCreateAction,
} from "./routes/group/GroupCreateView";

import ItemCreateView, {
  action as itemCreateAction,
  loader as itemCreateLoader,
} from "./routes/item/ItemCreateView";

import ItemEditView, {
  action as itemEditAction,
  loader as itemEditLoader,
} from "./routes/item/ItemEditView";

import TagEditView, {
  action as tagEditAction,
  loader as tagEditLoader,
} from "./routes/tag/TagEditView";

import TagCreateView, {
  action as tagCreateAction,
} from "./routes/tag/TagCreateView";

import LoginView from "./routes/LoginView";
import AccountView from "./routes/AccountView";

import UserContext from "./UserContext";
import TrackerSession from "./service/TrackerSession";
import UserNavBar from "./components/UserNavBar";
import UxTestView from "./routes/UxTestView";

const router = createBrowserRouter([
  {
    element: (
      <>
        <UserNavBar />
        <Outlet />
      </>
    ),
    children: [
      {
        path: "/",
        loader: groupListLoader,
        element: <GroupListView />,
      },
      {
        path: "/test",
        element: <UxTestView />
      },
      {
        path: "/login",
        element: <LoginView />,
      },
      {
        path: "/account",
        element: <AccountView />,
      },
      {
        path: "/group/create",
        action: groupCreateAction,
        element: <GroupCreateView />,
      },
      {
        path: "/group/:groupId/:groupName?",
        loader: groupViewLoader,
        element: <GroupView />,
      },
      {
        path: "/group/edit/:groupId/:groupName?",
        loader: groupLoader,
        action: groupAction,
        element: <GroupEditView />,
      },
      {
        path: "/group/:groupId/item/create",
        action: itemCreateAction,
        loader: itemCreateLoader,
        element: <ItemCreateView />,
      },
      {
        path: "/group/:groupId/item/:itemId/:itemName?",
        action: itemEditAction,
        loader: itemEditLoader,
        element: <ItemEditView />,
      },
      {
        path: "/tag/:tagId/:tagName?",
        action: tagEditAction,
        loader: tagEditLoader,
        element: <TagEditView />,
      },
      {
        path: "/tag/create",
        action: tagCreateAction,
        element: <TagCreateView />,
      },
    ],
  },
]);

function App() {
  const [userFetched, setUserFetched] = useState(false);
  const [user, setUser] = useState(null);

  const whoami = async () => {
    const user = await TrackerSession.whoami();
    setUser(user);
  };

  if (!userFetched) {
    whoami();
    setUserFetched(true);
  }

  const login = async (username, password) => {
    const user = await TrackerSession.login(username, password);
    setUser(user);
  };

  const logout = async () => {
    await TrackerSession.logout();
    setUser(null);
  };

  const isUserAuthenticated = () => {
    return TrackerSession.isUserAuthenticated();
  };

  const userContext = {
    user: user,
    isUserAuthenticated: isUserAuthenticated,
    login: login,
    logout: logout,
  };

  return (
    <UserContext.Provider value={userContext}>
      <RouterProvider router={router} />
    </UserContext.Provider>
  );
}

export default App;
