import { Link, useLoaderData } from "react-router-dom";
import GroupTable from "../components/GroupTable";
import Button from "react-bootstrap/Button";
import TrackerAPI from "../service/TrackerAPI";
import { useContext, useEffect } from "react";
import UserContext from "../UserContext";

export async function loader() {
  return {
    groups: await TrackerAPI.getGroups(),
    tags: await TrackerAPI.getTags(),
  };
}

const GroupListView = () => {
  const { groups, tags } = useLoaderData();
  const { isUserAuthenticated } = useContext(UserContext);

  useEffect(() => {
    document.title = "Tracker";
  }, []);

  if (!isUserAuthenticated()) {
    return (
      <>
        <h1>Welcome to the tracker. Please login.</h1>
      </>
    );
  }

  return (
    <>
      <h1>Groups</h1>
      <GroupTable groups={groups} tags={tags} />
      <Link to={`/group/create`}>
        <Button>Create new</Button>
      </Link>
    </>
  );
};

export default GroupListView;
