import { useEffect, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import LinkContainer from "react-router-bootstrap/LinkContainer";
import { Typeahead } from "react-bootstrap-typeahead";
import TrackerAPI from "../../service/TrackerAPI";
import { deobfuscate, obfuscate, replaceForUrl } from "../../util/Util";

export async function loader(r) {
  const group = await TrackerAPI.getGroup(deobfuscate(r.params.groupId));
  const tags = await TrackerAPI.getTags();

  return { group, tags };
}

async function addTags(selectedTags, groupId) {
  const selectedTagIds = [...selectedTags.map((t) => t.id)];
  await TrackerAPI.addTags(selectedTagIds, groupId);
}

const GroupView = () => {
  const { group, tags } = useLoaderData();
  let groupTagIds = group.relationships.tags.data.map((tag) => tag.id);
  const [selectedTags, setSelectedTags] = useState(
    tags.filter((t) => groupTagIds.includes(t.id))
  );

  useEffect(() => {
    document.title = group.attributes.name;  
    window.history.replaceState(
      null,
      null,
      `/group/${obfuscate(group.id)}/${replaceForUrl(group.attributes.name)}`
    );
  }, [group]);

  const items = group.attributes.items.map((item) => {
    return (
      <tr key={item.id}>
        <td>
          <Form.Check type="checkbox"></Form.Check>
        </td>
        <td>
          <Link
            to={`/group/${obfuscate(group.id)}/item/${obfuscate(item.id)}/${replaceForUrl(
              item.name
            )}`}
          >
            {item.name}
          </Link>
        </td>
        <td>{item.note}</td>
        <td>
          <a href={item.link}>{item.link}</a>
        </td>
        <td>${item.price}</td>
      </tr>
    );
  });
  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item href="/">Groups</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{group.attributes.name}</Breadcrumb.Item>
      </Breadcrumb>
      <h1>{group.attributes.name}</h1>
      <p>{group.attributes.description}</p>
      {group.attributes.note !== "" && (
        <>
          <h2>Notes</h2>
          <p>{group.attributes.note}</p>
        </>
      )}
      <Link to={`/group/edit/${obfuscate(group.id)}/`}>
        <Button>Edit</Button>
      </Link>
      <h3>Tags</h3>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "0.1em",
        }}
      >
        <Typeahead
          id="exampleId"
          multiple
          onChange={(t) => {
            setSelectedTags(t);
          }}
          options={tags}
          labelKey={(tag) => tag.attributes.name}
          defaultSelected={selectedTags}
          style={{ flexGrow: 2 }}
        />
        <Button
          variant="primary"
          onClick={() => addTags(selectedTags, group.id)}
        >
          Save tags....
        </Button>
      </div>
      <br />
      <Link to={"/tag/create"}>
        <Button variant="primary">Create new</Button>
      </Link>
      <br />
      <Table hover size="sm">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            {/* <th>Description</th> */}
            <th>Notes</th>
            <th>Link</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </Table>
      <Link to={`/group/${obfuscate(group.id)}/item/create`}>
        <Button variant="primary">Create new</Button>
      </Link>
    </>
  );
};

export default GroupView;
