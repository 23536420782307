import { redirect, useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import UserContext from "../UserContext";
import { useCallback, useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function LoginView() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const { isUserAuthenticated, login } = useContext(UserContext);
  const [checkIsUserAuthenticated, setCheckIsUserAuthenticated] =
    useState(false);
  const navigate = useNavigate();

  const redirectToNextPage = useCallback(() => {
    const params = new URL(document.location).searchParams;
    const nextUrl = params.get("next");
    if (nextUrl && nextUrl !== "/login") {
      return redirect(nextUrl);
    }
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    document.title = "Tracker - Login";
  }, []);

  useEffect(() => {
    if (!checkIsUserAuthenticated) {
      setCheckIsUserAuthenticated(true);
      if (isUserAuthenticated()) {
        redirectToNextPage();
      }
    }
  }, [
    isUserAuthenticated,
    checkIsUserAuthenticated,
    setCheckIsUserAuthenticated,
    redirectToNextPage,
  ]);

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      await login(username, password);
      redirectToNextPage();
    } catch (e) {
      setError(e.message);
    }
  }

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  let errorElement = <></>;
  if (error) {
    errorElement = (
      <Alert key={"danger"} variant={"danger"}>
        {error}
      </Alert>
    );
  }

  return (
    <>
      <h1>Login</h1>
      {errorElement}
      <Form method="post" id="group-form" onSubmit={handleSubmit}>
        <Form.Label>Username</Form.Label>
        <Form.Control
          required={true}
          onChange={handleUsernameChange}
        ></Form.Control>
        <br />
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          required={true}
          onChange={handlePasswordChange}
        ></Form.Control>
        <br />
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
}
