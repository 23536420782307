import Cookies from "js-cookie";

/* Client API */
export default class TrackerAPI {
  static getCsrfToken() {
    return Cookies.get("csrftoken");
  }

  static async login(username, password) {
    const response = await fetch(`/api/auth/login/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    if (response.status === 200) {
      return await response.json();
    }

    if (response.status === 400) {
      throw new Error("Username and password are required!");
    }

    if (response.status === 403) {
      throw new Error("Invalid username/password");
    }

    throw new Error("Unknown");
  }

  static async logout() {
    await fetch(`/api/auth/logout/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "X-CSRFToken": this.getCsrfToken(),
      },
    });
  }

  static async whoami() {
    const response = await fetch(`/api/auth/whoami/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
    });

    if (response.status === 200) {
      return await response.json();
    }

    if (response.status === 403) {
      throw new Error("User is not authenticated");
    }

    throw new Error("Unknown error!");
  }

  static async getGroups() {
    const { data: groups } = await fetch(`/api/tracker/group/`).then(
      (response) => response.json()
    );
    return groups;
  }

  static async getGroup(id) {
    const { data: group } = await fetch(`/api/tracker/group/${id}/`).then(
      (response) => response.json()
    );
    return group;
  }

  static async createGroup(attributes) {
    const { data: group } = await fetch(`/api/tracker/group/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
      body: JSON.stringify({
        data: {
          type: "Group",
          attributes: attributes,
        },
      }),
    }).then((response) => response.json());

    return group;
  }

  static async updateGroup(id, attributes) {
    const data = {
      data: {
        type: "Group",
        id: id,
        attributes: attributes,
      },
    };

    await fetch(`/api/tracker/group/${id}/`, {
      method: "PATCH",
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
  }

  static async deleteGroup(id) {
    await fetch(`/api/tracker/group/${id}/`, {
      method: "DELETE",
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
    });
  }

  static async getTags() {
    const { data: tags } = await fetch(`/api/tracker/tag/`).then((response) =>
      response.json()
    );
    return tags;
  }

  static async addTags(selectedTagIds, groupId) {
    const data = {
      data: {
        type: "Group",
        id: groupId,
        relationships: {
          tags: {
            data: selectedTagIds,
          },
        },
      },
    };

    await fetch(`/api/tracker/group/${groupId}/`, {
      method: "PATCH",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
      body: JSON.stringify(data),
    });
  }

  static async createTag(attributes) {
    const response = await fetch(`/api/tracker/tag/`, {
      method: "POST",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
      body: JSON.stringify({
        data: {
          type: "Tag",
          attributes: attributes,
        },
      }),
    });

    if (response.ok) {
      const { data: tag } = await response.json();
      return tag;
    }

    throw new Error(response.statusText);
  }

  static async getTag(id) {
    const { data: tag } = await fetch(`/api/tracker/tag/${id}/`).then(
      (response) => response.json()
    );
    return tag;
  }

  static async updateTag(id, attributes) {
    return await fetch(`/api/tracker/tag/${id}/`, {
      method: "PATCH",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
      body: JSON.stringify({
        data: {
          type: "Tag",
          id: id,
          attributes: attributes,
        },
      }),
    });
  }

  static async deleteTag(id) {
    await fetch(`/api/tracker/tag/${id}/`, {
      method: "DELETE",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
    });
  }

  static async createItem(groupId, attributes) {
    const data = {
      data: {
        type: "Item",
        attributes: {
          ...attributes,
          group: groupId,
        },
      },
    };

    const { data: item } = await fetch(`/api/tracker/item/`, {
      method: "POST",
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    }).then((response) => response.json());

    return item;
  }

  static async getItem(id) {
    const { data: item } = await fetch(`/api/tracker/item/${id}/`).then(
      (response) => response.json()
    );
    return item;
  }

  static async updateItem(id, attributes) {
    const data = {
      data: {
        type: "Item",
        id: id,
        attributes: {
          ...attributes,
        },
      },
    };

    await fetch(`/api/tracker/item/${id}/`, {
      method: "PATCH",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
      body: JSON.stringify(data),
    });
  }

  static async deleteItem(itemId) {
    await fetch(`/api/tracker/item/${itemId}/`, {
      method: "DELETE",
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-CSRFToken": this.getCsrfToken(),
      },
    });
  }
}
