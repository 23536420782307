import GroupForm from "../../components/forms/GroupForm";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import LinkContainer from "react-router-bootstrap/LinkContainer";
import { useLoaderData, Link, redirect, useNavigate } from "react-router-dom";
import TrackerAPI from "../../service/TrackerAPI";
import { useEffect } from "react";
import { deobfuscate, obfuscate } from "../../util/Util";

export async function loader({ params }) {
  return {
    group: await TrackerAPI.getGroup(deobfuscate(params.groupId)),
  };
}

export async function action({ request, params }) {
  const formData = await request.formData();
  const attributes = Object.fromEntries(formData);
  await TrackerAPI.updateGroup(params.groupId, attributes);
  return redirect(`/group/${params.groupId}`);
}

export async function deleteGroup(id, navigate) {
  await TrackerAPI.deleteGroup(id);
  return navigate(`/`);
}

const GroupEditView = () => {
  const { group } = useLoaderData();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Edit - ${group.attributes.name}`;
  }, []);

  return (
    <div>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item href="/">Groups</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to={`/group/${obfuscate(group.id)}`}>
          <Breadcrumb.Item>{group.attributes.name}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Edit this group...</Breadcrumb.Item>
      </Breadcrumb>
      <GroupForm
        name={group.attributes.name}
        description={group.attributes.description}
        note={group.attributes.note}
      />
      <Link to={`/group/${obfuscate(group.id)}`}>
        <Button variant="secondary">Cancel</Button>
      </Link>
      <Button variant="danger" onClick={() => deleteGroup(group.id, navigate)}>
        Delete
      </Button>
    </div>
  );
};

export default GroupEditView;
