const REPLACE_WITH_HYPHEN = '\+/._ '.split('').reduce((map, obj) => (map[obj] = true, map), {});
const ALLOWED_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789 '.split('').reduce((map, obj) => (map[obj] = true, map), {});

/**
 * Creates a URL safe version of a group, list, or item name so that it can be human readable
 * in the URL.
 * 
 * @param {string} name 
 * @returns {string} a URL safe string representing the name
 */
export const replaceForUrl = (name) => {
    return name
        .toLowerCase()
        .split('')
        .reduce((a, b) => REPLACE_WITH_HYPHEN[b] ? a + ' ' : a + b)
        .split('')
        .reduce((a, b) => ALLOWED_CHARACTERS[b] ? a + b : a)
        .split(' ')
        .map((s) => s.trim())
        .filter(s => s !== "")
        .join('-')
        .slice(0, 64)
}

/**
 * Performs a bitshift for numbers less than 0xFFFFFF. The following shifts occur:
 * 1. first bit switched the last spot
 * 2. second bit switched with second to last spot
 * 3. first half (minus first two bits) switched with last half (minus second two bits)
 * 
 * @param {number} n A number, less than 0xFFFFFF
 * @returns {number} The bit-shifted version of the number
 */
const bitShift = (n) => {
    // First bits to end
    const bit0 = ((0x000001 & n) << 23);
    const bit1 = ((0x000002 & n) << 21);
    
    // Last bits to start
    const bit23 = ((0x800000 & n) >> 23);
    const bit22 = ((0x400000 & n) >> 21);

    // Lift and shift middles
    const leftHalf = ((0x000FFC & n) << 10);
    const rightHalf = ((0x3FF000 & n) >> 10);

    // Add them together
    const obfN = bit0 + bit1 + bit23 + bit22 + leftHalf + rightHalf;
    return obfN;
}

/**
 * Changes a plain integer ID to an obfuscated version represented in base 36
 * 
 * @param {number} n The number to obfuscate
 * @returns {string} An obfuscated version of the number, bitshifted and represented in base 36
 */
export const obfuscate = (n) => bitShift(n).toString(36).toUpperCase();

/**
 * Converts an obfuscated ID to an plain integer ID
 * 
 * @param {string} id The obfuscated version of the number
 * @returns {number} The plain integer ID
 */
export const deobfuscate = (id) => bitShift(Number.parseInt(id, 36));
