import TrackerAPI from "./TrackerAPI";

/* Stateful Session Tracking */
export default class TrackerSession {
  static USER_STORAGE_KEY = "user";

  static isUserAuthenticated() {
    return !!localStorage.getItem(TrackerSession.USER_STORAGE_KEY);
  }

  static async whoami() {
    // No user details
    if (!localStorage.getItem(TrackerSession.USER_STORAGE_KEY)) {
      try {
        const user = await TrackerAPI.whoami();
        localStorage.setItem(
          TrackerSession.USER_STORAGE_KEY,
          JSON.stringify(user)
        );
      } catch (e) {
        return null;
      }
    }

    return JSON.parse(localStorage.getItem(TrackerSession.USER_STORAGE_KEY));
  }

  static async login(username, password) {
    const user = await TrackerAPI.login(username, password);
    localStorage.setItem(TrackerSession.USER_STORAGE_KEY, JSON.stringify(user));
    return user;
  }

  static async logout() {
    await TrackerAPI.logout();
    localStorage.removeItem(TrackerSession.USER_STORAGE_KEY);
  }
}
