import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import LinkContainer from "react-router-bootstrap/LinkContainer";
import { useLoaderData, Link, redirect, useNavigate } from "react-router-dom";
import ItemForm from "../../components/forms/ItemForm";
import TrackerAPI from "../../service/TrackerAPI";
import { useEffect } from "react";
import { deobfuscate, obfuscate } from "../../util/Util";

export async function loader(r) {
  return {
    group: await TrackerAPI.getGroup(deobfuscate(r.params.groupId)),
    item: await TrackerAPI.getItem(deobfuscate(r.params.itemId)),
  };
}

export async function action({ request, params }) {
  const formData = await request.formData();
  const attributes = Object.fromEntries(formData);
  await TrackerAPI.updateItem(deobfuscate(params.itemId), attributes);

  return redirect(`/group/${params.groupId}`);
}

export async function deleteItem(navigate, groupId, itemId) {
  await TrackerAPI.deleteItem(itemId);

  return navigate(`/group/${obfuscate(groupId)}/`);
}

const ItemEditView = () => {
  const { group, item } = useLoaderData();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Edit - ${item.attributes.name}`;
  }, [item]);

  return (
    <div>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item href="/">Groups</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to={`/group/${obfuscate(group.id)}`}>
          <Breadcrumb.Item>{group.attributes.name}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{item.attributes.name}</Breadcrumb.Item>
      </Breadcrumb>
      <ItemForm
        name={item.attributes.name}
        description={item.attributes.description}
        note={item.attributes.note}
        link={item.attributes.link}
        price={item.attributes.price}
      />
      <Link to={`/group/${obfuscate(group.id)}`}>
        <Button variant="secondary">Cancel</Button>
      </Link>
      <Button
        variant="danger"
        onClick={() => deleteItem(navigate, group.id, item.id)}
      >
        Delete
      </Button>
    </div>
  );
};

export default ItemEditView;
