import Form from "react-bootstrap/Form";
import { Form as RouterForm } from "react-router-dom";
import Button from "react-bootstrap/Button";

const ItemForm = (props) => {
  return (
    <RouterForm method="post" id="group-form">
      <Form.Label>Name</Form.Label>
      <Form.Control name="name" defaultValue={props.name}></Form.Control>
      <br />
      <Form.Label>Description</Form.Label>
      <Form.Control
        name="description"
        defaultValue={props.description}
      ></Form.Control>
      <Form.Label>Notes</Form.Label>
      <Form.Control name="note" defaultValue={props.note}></Form.Control>
      <Form.Label>Link</Form.Label>
      <Form.Control name="link" defaultValue={props.link}></Form.Control>
      <Form.Label>Price</Form.Label>
      <Form.Control
        name="price"
        type="number"
        defaultValue={props.price}
      ></Form.Control>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </RouterForm>
  );
};

export default ItemForm;
