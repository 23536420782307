import ItemForm from "../../components/forms/ItemForm";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import LinkContainer from "react-router-bootstrap/LinkContainer";
import { useLoaderData, redirect, useNavigate } from "react-router-dom";
import TrackerAPI from "../../service/TrackerAPI";
import { useEffect } from "react";
import { deobfuscate, obfuscate } from "../../util/Util";

export async function loader({ params }) {
  return { group: await TrackerAPI.getGroup(deobfuscate(params.groupId)) };
}

export async function action({ request, params }) {
  const formData = await request.formData();
  const attributes = Object.fromEntries(formData);
  await TrackerAPI.createItem(deobfuscate(params.groupId), attributes);

  return redirect(`/group/${params.groupId}/`);
}

const ItemCreateView = () => {
  const navigate = useNavigate();
  const { group } = useLoaderData();

  useEffect(() => {
    document.title = `Create item...`;
  }, []);

  return (
    <div>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item href="/">Groups</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to={`/group/${obfuscate(group.id)}/`}>
          <Breadcrumb.Item>{group.attributes.name}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>Create item...</Breadcrumb.Item>
      </Breadcrumb>
      <ItemForm />
      <Button onClick={() => navigate(-1)} variant="secondary">
        Cancel
      </Button>
    </div>
  );
};

export default ItemCreateView;
