import { useLoaderData, redirect, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Tagform from "../../components/forms/TagForm";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import TrackerAPI from "../../service/TrackerAPI";
import { replaceForUrl } from "../../util/Util";
import { deobfuscate, obfuscate } from "../../util/Util";

export async function loader(r) {
  return {
    tag: await TrackerAPI.getTag(deobfuscate(r.params.tagId)),
  };
}

export async function action({ request, params }) {
  const formData = await request.formData();
  const attributes = Object.fromEntries(formData);
  await TrackerAPI.updateTag(params.tagId, attributes);

  return redirect(`/`);
}

export async function deleteItem(navigate, tagId) {
  await TrackerAPI.deleteTag(tagId);
  return navigate("/");
}

const TagEditView = () => {
  const { tag } = useLoaderData();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Edit - ${tag.attributes.name}`;
    window.history.replaceState(
      null,
      null,
      `/tag/${obfuscate(tag.id)}/${replaceForUrl(tag.attributes.name)}`
    );
  }, [tag]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Tags</Breadcrumb.Item>
        <Breadcrumb.Item active>{tag.attributes.name}</Breadcrumb.Item>
      </Breadcrumb>
      <Tagform
        name={tag.attributes.name}
        description={tag.attributes.description}
      />
      <Button variant="secondary" onClick={() => navigate(-1)}>
        Cancel
      </Button>
      <Button variant="danger" onClick={() => deleteItem(navigate, tag.id)}>
        Delete
      </Button>
    </>
  );
};

export default TagEditView;
